import { Languages } from '../../constants'
import Hamburguer from '../Atoms/Buttons/Hamburguer/Hamburguer'
import Link from '../Atoms/Link/Link'

import { useEffect, useState } from 'react'

import classNames from 'classnames'
import LinkFlag from 'components/Atoms/LinkFlag'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

export interface HeaderProps {
  selected?: string
  onClick: (section: string) => void
  logo: string
  className?: string
  hideOptions?: boolean
}

const Header = ({
  selected,
  onClick,
  logo,
  className,
  hideOptions,
}: HeaderProps) => {
  const [t, i18n] = useTranslation()
  const language = i18n.language
  const [isOpen, setIsOpen] = useState(false)
  const navClassname = classNames('bg-white', 'p-4', {
    'flex flex-col flex-grow pb-4 lg:pb-0 lg:flex lg:justify-center lg:flex-row':
      isOpen,
    'hidden lg:flex lg:flex-row lg:justify-center flex-col lg:align-center':
      !isOpen,
  })
  const linkClassname = classNames({
    'ml-7': !isOpen,
  })

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isOpen &&
        !event.target.closest('.nav-container') &&
        !event.target.closest('.hamburger-button')
      ) {
        closeMenu()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const languageBtn = () => {
    if (language === Languages.BASQUE) {
      return Languages.SPANISH
    } else {
      return Languages.BASQUE
    }
  }

  const handleClick = (section: string) => {
    onClick(section)
    closeMenu()
  }
  return (
    <div className={className}>
      <div className="antialiased bg-white dark-mode:bg-white">
        <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-white nav-container">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto lg:items-center lg:justify-between lg:flex-row lg:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-between p-4">
              <RouterLink to="/">
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_URL_S3}/images/${logo}`}
                  width={200}
                  height={30}
                />
              </RouterLink>
              <Hamburguer
                className="lg:hidden focus:outline-none focus:shadow-outline"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
            <nav className={navClassname}>
              {!hideOptions && (
                <>
                  <Link
                    selected={selected === 'how-it-works'}
                    className={linkClassname}
                    onClick={() => handleClick('how-it-works')}
                  >
                    {t('header.how-it-works')}
                  </Link>
                  <Link
                    selected={selected === 'prices'}
                    className={linkClassname}
                    onClick={() => handleClick('prices')}
                  >
                    {t('header.prices')}
                  </Link>
                  <Link
                    selected={selected === 'ranking'}
                    className={linkClassname}
                    onClick={() => handleClick('ranking')}
                  >
                    {t('header.ranking')}
                  </Link>
                  <Link
                    selected={selected === 'analytics'}
                    className={linkClassname}
                    onClick={() => handleClick('analytics')}
                  >
                    {t('header.analytics')}
                  </Link>
                  <Link
                    selected={selected === 'contact'}
                    className={linkClassname}
                    onClick={() => handleClick('contact')}
                  >
                    {t('header.contact')}
                  </Link>
                  <div className="md:ml-10 md:mt-0 flex justify-center mt-5">
                    <div className="flex flex-row ml-3">
                      <LinkFlag
                        language={languageBtn()}
                        height={15}
                        onClick={() => i18n.changeLanguage(`${languageBtn()}`)}
                      />
                    </div>
                  </div>
                </>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
