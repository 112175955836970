import { Icon } from 'models/icons'

export default function BasqueFlag({ width, height }: Icon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height || 28}
      viewBox="0 0 50 28"
      xmlSpace="preserve"
      data-testid="flag"
    >
      <path fill="#D52B1E" d="M0 0v28h50V0z" />
      <path stroke="#009B48" strokeWidth={4.3} d="m0 0 50 28m0-28L0 28" />
      <path stroke="#fff" strokeWidth={4.3} d="M25 0v28M0 14h50" />
    </svg>
  )
}
