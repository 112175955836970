import classNames from 'classnames'
import CiclogreenIcon from 'components/Atoms/Icons/CiclogreenIcon'

export default function LoadingView({ t }: any) {
  const loadingClassName = classNames(
    'w-scren',
    'h-screen',
    'flex',
    'justify-center',
    'items-center',
  )
  const elementsClassName = classNames(
    'flex',
    'flex-col',
    'justify-center',
    'items-center',
  )
  const spinnerBoxClassName = classNames(
    'absolute',
    'top-1/2',
    'left-1/2',
    'transform',
    '-translate-x-1/2',
    '-translate-y-1/2',
    'w-40',
    'h-40',
    'flex',
    'justify-center',
    'items-center',
    'bg-transparent',
    'z-0',
  )
  const borderCircleClassName = classNames(
    'w-40',
    'h-40',
    'flex',
    'justify-center',
    'items-center',
    'rounded-full',
    'bg-gradient-to-b from-white via-green-500 to-blue-500',
    'animate-spin',
  )
  const circleCoreClassName = classNames(
    'w-36',
    'h-36',
    'bg-white',
    'rounded-full',
  )
  return (
    <div className={loadingClassName}>
      <div className={elementsClassName}>
        <div className="relative">
          <div className="relative z-10">
            <CiclogreenIcon width={130} height={130} />
          </div>
          <div className={spinnerBoxClassName}>
            <div className={borderCircleClassName}>
              <div className={circleCoreClassName}></div>
            </div>
          </div>
        </div>
        <h4 className="mt-6 animate-pulse">{t('loading')}</h4>
      </div>
    </div>
  )
}
