/* eslint-disable */
enum Languges {
  SPANISH = 'es',
  BASQUE = 'eu',
  CATALAN = 'ca',
  ENGLISH = 'en',
  GERMAN = 'de',
}
/* eslint-enable */
export default Languges
